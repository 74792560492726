import { createSlice } from "@reduxjs/toolkit";
const createUserInitialState = {
  data: null,
  error: null,
  isLoading: false
};
const user_detailsInitialState = {
  data: null,
  error: null,
  isLoading: false
};
const forgetPassword_initial_state = {
  data: null,
  error: null,
  isLoading: false
};
const resetPassword_initial_state = {
  data: null,
  error: null,
  isLoading: false
};
const login_initial_state = {
  data: null,
  error: null,
  isLoading: false
};
const getUser_initialState = {
  data: null,
  error: null,
  isLoading: false
};
const updateUser_initialState = {
  data: null,
  error: null,
  isLoading: false
};
const updateLocation_initialState = {
  data: null,
  error: null,
  isLoading: false
};
const updatePassword_initialState = {
  data: null,
  error: null,
  isLoading: false
};
const createEmployee_initialState = {
  data: null,
  error: null,
  isLoading: false
};

const createEmployee = createSlice({
    name: 'createEmployee',
    initialState:createEmployee_initialState,
    reducers: {
        createEmployeeStart: state => {
            state.isLoading = true;
        },
        createEmployeeSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createEmployeeError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const createuser = createSlice({
    name: 'createuser',
    initialState:createUserInitialState,
    reducers: {
        createUserStart: state => {
            state.isLoading = true;
        },
        createUserSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createUserError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const user_details = createSlice({
    name: 'user_details',
    initialState:user_detailsInitialState,
    reducers: {
        userDetailsStart: state => {
            state.isLoading = true;
        },
        userDetailsSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        userDetailsError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const login = createSlice({
    name: 'login',
    initialState:login_initial_state,
    reducers: {
        loginStart: state => {
            state.isLoading = true;
        },
        loginSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        loginError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const forgetPassword = createSlice({
    name: 'forgetPassword',
    initialState:forgetPassword_initial_state,
    reducers: {
        forgetPasswordStart: state => {
            state.isLoading = true;
        },
        forgetPasswordSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        forgetPasswordError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const ResetPassword = createSlice({
    name: 'resetPassword',
    initialState:resetPassword_initial_state,
    reducers: {
        ResetPasswordStart: state => {
            state.isLoading = true;
        },
        ResetPasswordSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        ResetPasswordError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const getUser = createSlice({
    name: 'getUser',
    initialState:getUser_initialState,
    reducers: {
        getUserStart: state => {
            state.isLoading = true;
        },
        getUserSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload.user;
            state.organizationData = action.payload.organizationData
        },
        getUserError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const updateUser = createSlice({
    name: 'updateUser',
    initialState:updateUser_initialState,
    reducers: {
        updateUserStart: state => {
            state.isLoading = true;
        },
        updateUserSuccess: (state, action) => {
            console.log("action",action)
            state.isLoading = false;
            state.data = action.payload;
        },
        updateUserError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const updateLocation = createSlice({
    name: 'getUser',
    initialState:updateLocation_initialState,
    reducers: {
        updateLocationStart: state => {
            state.isLoading = true;
        },
        updateLocationSuccess: (state, action) => {
            console.log("action",action)
            state.isLoading = false;
            state.data = action.payload;
        },
        updateLocationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const updatePassword = createSlice({
    name: 'updatePassword',
    initialState:updateLocation_initialState,
    reducers: {
        updatePasswordStart: state => {
            state.isLoading = true;
        },
        updatePasswordSuccess: (state, action) => {
            console.log("action",action)
            state.isLoading = false;
            state.data = action.payload;
        },
        updatePasswordError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;                  
        },
    },
})

export const { createEmployeeError,createEmployeeStart,createEmployeeSuccess } = createEmployee.actions;
export const { createUserError,createUserStart,createUserSuccess } = createuser.actions;
export const {userDetailsStart,userDetailsSuccess,userDetailsError } = user_details.actions;
export const {loginStart,loginSuccess,loginError } = login.actions;
export const {ResetPasswordStart,ResetPasswordSuccess,ResetPasswordError } = ResetPassword.actions;
export const {forgetPasswordStart,forgetPasswordSuccess,forgetPasswordError } = forgetPassword.actions;
export const {getUserStart,getUserSuccess,getUserError } = getUser.actions;
export const {updateUserStart,updateUserSuccess,updateUserError } = updateUser.actions;
export const {updateLocationStart,updateLocationSuccess,updateLocationError } = updateLocation.actions; 
export const {updatePasswordStart,updatePasswordSuccess,updatePasswordError } = updatePassword.actions; 

export default {createEmployee: createEmployee.reducer, createuser:createuser.reducer,user_details:user_details.reducer,forgetPassword:forgetPassword.reducer,resetPassword:ResetPassword.reducer,login:login.reducer,getUser:getUser.reducer,updateUser:updateUser.reducer,updateLocation:updateLocation.reducer,updatePassword:updatePassword.reducer}
