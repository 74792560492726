import React, { lazy,useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { Elements } from 'react-stripe-elements'
import Stripe from 'stripe'
import {useSelector} from 'react-redux'
import './style.css'
import ProtectedRoute from './routes/ProtectedRoute'
import 'react-toastify/dist/ReactToastify.css';


const Layout = lazy(() => import('./containers/Layout'))
// const Login = lazy(() => import('./pages/Login'))
// const CreateAccount = lazy(() => import('./pages/CreateAccount'))
// const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const UserDetails = lazy(() => import('./pages/UserDetails'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'))
const InviteMember = lazy(() => import('./pages/Invitation'))
function App() {
 

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          {/* <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} /> */}
       
          <Route path="/user-details" component={UserDetails} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/user-invitation" component={InviteMember} />

         

          {/* Place new routes over this */}
          <ProtectedRoute ath="/app" component={Layout} />
          {/* <Route path="/app" component={Layout} /> */}
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
