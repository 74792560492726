import { createSlice } from "@reduxjs/toolkit";
const organization_initial_setup = {
    data: null,
    error: null,
    isLoading: false,
  };
const get_organization_initial_setup = {
    data:null,
    error: null,
    isLoading: false,
  };
const get_organization_Detail = {
    data:null,
    error: null,
    isLoading: false,
  };
  
const create_location_state={
    data:null,
    error: null,
    isLoading: false,
}
const delete_location_state={
    data:null,
    error: null,
    isLoading: false,
}
const fetch_location_state={
    data:null,
    error: null,
    isLoading: false,
}
const update_location_state={
    data:null,
    error: null,
    isLoading: false,
}
const get_desgination = {
    data:null,
    error: null,
    isLoading: false,
  };
const create_desgination = {
    data:null,
    error: null,
    isLoading: false,
  };
  const create_workflow = {
    data:null,
    error: null,
    isLoading: false,
  };
const update_desgination = {
    data:null,
    error: null,
    isLoading: false,
  };
const delete_desgination = {
    data:null,
    error: null,
    isLoading: false,
  };
  const get_department = {
    data:null,
    error: null,
    isLoading: false,
  };
const create_department = {
    data:null,
    error: null,
    isLoading: false,
  };
const update_department = {
    data:null,
    error: null,
    isLoading: false,
  };
const delete_department = {
    data:null,
    error: null,
    isLoading: false,
  };
const create_employee_state = {
    data:null,
    error: null,
    isLoading: false,
  };
  const inviteEmployee_initialState = {
    data: null,
    error: null,
    isLoading: false
  };
  const fetchEmployee_initialState = {
    data: null,
    error: null,
    isLoading: false
  };
  const deleteEmployee_initialState = {
    data: null,
    error: null,
    isLoading: false
  };
  
  const updateEmployee_initialState = {
    data: null,
    error: null,
    isLoading: false
  };

  
  const inviteEmployee = createSlice({
      name: 'inviteEmployee',
      initialState:inviteEmployee_initialState,
      reducers: {
          inviteEmployeeStart: state => {
              state.isLoading = true;
          },
          inviteEmployeeSuccess: (state, action) => {
              state.isLoading = false;
              state.data = action.payload;
          },
          inviteEmployeeError: (state, action) => {
              state.isLoading = false;
              state.error = action.payload;
          },
      },
  })
  

  const createEmployee = createSlice({
    name: 'createemployee',
    initialState:create_employee_state,
    reducers: {
        createEmployeeStart: state => {
            state.isLoading = true;
        },
        createEmployeeSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createEmployeeError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
  const updateEmployee = createSlice({
    name: 'updateemployee',
    initialState:updateEmployee_initialState,
    reducers: {
        updateEmployeeStart: state => {
            state.isLoading = true;
        },
        updateEmployeeSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        updateEmployeeError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const fetchEmployees = createSlice({
    name: 'fetchemployees',
    initialState:fetchEmployee_initialState,
    reducers: {
        fetchEmployeeStart: state => {
            state.isLoading = true;
        },
        fetchEmployeeSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchEmployeeError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
 
const deleteEmployee = createSlice({
    name: 'deleteemployees',
    initialState:deleteEmployee_initialState,
    reducers: {
        deleteEmployeeStart: state => {
            state.isLoading = true;
        },
        deleteEmployeeSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        deleteEmployeeError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
 

  const createLocation = createSlice({
    name: 'createlocation',
    initialState:create_location_state,
    reducers: {
        createLocationStart: state => {
            state.isLoading = true;
        },
        createLocationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createLocationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
  const deleteLocation = createSlice({
    name: 'deletelocation',
    initialState:delete_location_state,
    reducers: {
        deleteLocationStart: state => {
            state.isLoading = true;
        },
        deleteLocationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        deleteLocationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
  const fetchLocations = createSlice({
    name: 'fetchlocations',
    initialState:fetch_location_state,
    reducers: {
        fetchLocationStart: state => {
            state.isLoading = true;
        },
        fetchLocationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchLocationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
  const updateLocation = createSlice({
    name: 'updatelocations',
    initialState:update_location_state,
    reducers: {
        updateLocationStart: state => {
            state.isLoading = true;
        },
        updateLocationSuccess: (state, action) => {
            state.isLoading = false;
            // state.data = action.payload;
        },
        updateLocationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const createDesgination = createSlice({
    name: 'createdesignation',
    initialState:create_desgination,
    reducers: {
        createDesignationStart: state => {
            state.isLoading = true;
        },
        createDesignationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createDesignationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const createWorkflow = createSlice({
    name: 'creatWorkflow',
    initialState:create_workflow,
    reducers: {
        createWorkflowStart: state => {
            state.isLoading = true;
        },
        createWorkflowSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createWorkflowError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const updateDesignation = createSlice({
    name: 'updatedesignation',
    initialState:update_desgination,
    reducers: {
        updateDesignationStart: state => {
            state.isLoading = true;
        },
        updateDesignationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        updateDesignationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const fetchDesignations = createSlice({
    name: 'fetchdesignations',
    initialState:get_desgination,
    reducers: {
        fetchDesignationStart: state => {
            state.isLoading = true;
        },
        fetchDesignationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDesignationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const deleteDesignation = createSlice({
    name: 'deleteDesignation',
    initialState:delete_desgination,
    reducers: {
        deleteDesignationStart: state => {
            state.isLoading = true;
        },
        deleteDesignationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        deleteDesignationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const createDepartment = createSlice({
    name: 'createdepartment',
    initialState:create_department,
    reducers: {
        createDepartmentStart: state => {
            state.isLoading = true;
        },
        createDepartmentSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createDepartmentError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const updateDepartment = createSlice({
    name: 'updatedepartment',
    initialState:update_department,
    reducers: {
        updateDepartmentStart: state => {
            state.isLoading = true;
        },
        updateDepartmentSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        updateDepartmentError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

const fetchDepartments = createSlice({
    name: 'fetchdepartments',
    initialState:get_department,
    reducers: {
        fetchDepartmentStart: state => {
            state.isLoading = true;
        },
        fetchDepartmentSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        fetchDepartmentError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const deleteDepartment = createSlice({
    name: 'deletedepartment',
    initialState:delete_department,
    reducers: {
        deleteDepartmentStart: state => {
            state.isLoading = true;
        },
        deleteDepartmentSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        deleteDepartmentError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})



const organization = createSlice({
    name: 'organization',
    initialState:organization_initial_setup,
    reducers: {
        createOrganizationStart: state => {
            state.isLoading = true;
        },
        createOrganizationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        createOrganizationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const organizationType = createSlice({
    name: 'organizationType',
    initialState:get_organization_initial_setup,
    reducers: {
        getOrganizationStart: state => {
            state.isLoading = true;
        },
        getOrganizationSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getOrganizationError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
const getOrganizationDetail = createSlice({
    name: 'getOrganizationDetail',
    initialState:get_organization_Detail,
    reducers: {
        getOrganizationDetailStart: state => {
            state.isLoading = true;
        },
        getOrganizationDetailSuccess: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        },
        getOrganizationDetailError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})

export const { createOrganizationError,createOrganizationStart,createOrganizationSuccess } = organization.actions;
export const { getOrganizationError,getOrganizationStart,getOrganizationSuccess } = organizationType.actions;
export const { getOrganizationDetailError,getOrganizationDetailStart,getOrganizationDetailSuccess } = getOrganizationDetail.actions;

export const { createLocationError,createLocationSuccess,createLocationStart } = createLocation.actions;
export const { fetchLocationError,fetchLocationSuccess,fetchLocationStart } = fetchLocations.actions;
export const { updateLocationError,updateLocationSuccess,updateLocationStart } = updateLocation.actions;
export const { deleteLocationError,deleteLocationSuccess,deleteLocationStart } = deleteLocation.actions;

export const { createDesignationError,createDesignationSuccess,createDesignationStart } = createDesgination.actions;
export const { createWorkflowError,createWorkflowSuccess,createWorkflowStart } = createWorkflow.actions;
export const { fetchDesignationError,fetchDesignationSuccess,fetchDesignationStart } = fetchDesignations.actions;
export const { deleteDesignationError,deleteDesignationSuccess,deleteDesignationStart } = deleteDesignation.actions;
export const { updateDesignationError,updateDesignationSuccess,updateDesignationStart } = updateDesignation.actions;

export const { createDepartmentError,createDepartmentSuccess,createDepartmentStart } = createDepartment.actions;
export const { fetchDepartmentError,fetchDepartmentSuccess,fetchDepartmentStart } = fetchDepartments.actions;
export const { deleteDepartmentError,deleteDepartmentSuccess,deleteDepartmentStart } = deleteDepartment.actions;
export const { updateDepartmentError,updateDepartmentSuccess,updateDepartmentStart } = updateDepartment.actions;

export const { createEmployeeError,createEmployeeSuccess,createEmployeeStart } = createEmployee.actions;
export const { inviteEmployeeError,inviteEmployeeSuccess,inviteEmployeeStart } = inviteEmployee.actions;
export const { fetchEmployeeError,fetchEmployeeSuccess,fetchEmployeeStart } = fetchEmployees.actions;
export const { deleteEmployeeError,deleteEmployeeSuccess,deleteEmployeeStart } = deleteEmployee.actions;
export const { updateEmployeeError,updateEmployeeSuccess,updateEmployeeStart } = updateEmployee.actions;



export default {
    createOrganization:organization.reducer,
    getOrganizationType: organizationType.reducer,
    getOrganizationDetail: getOrganizationDetail.reducer,
    createLocationDetails:createLocation.reducer,
    fetchLocationsDetails:fetchLocations.reducer,
    updateLocationsDetails:updateLocation.reducer,
    deleteLocationsDetails:deleteLocation.reducer,
    fetchDesignations:fetchDesignations.reducer,
    createDesgination: createDesgination.reducer,
    deleteDesignation: deleteDesignation.reducer,
    updateDesignation: updateDesignation.reducer,    
    fetchDepartments:fetchDepartments.reducer,
    createDepartment: createDepartment.reducer,
    deleteDepartment: deleteDepartment.reducer,
    updateDepartment: updateDepartment.reducer,
    createEmployee: createEmployee.reducer,
    inviteEmployee: inviteEmployee.reducer,
    fetchEmployees: fetchEmployees.reducer,
    deleteEmployee: deleteEmployee.reducer,
    updateEmployee: updateEmployee.reducer

}