import { createSlice } from "@reduxjs/toolkit";
const getStripeProductsInitialState = {
  data: null,
  error: null,
  isLoading: false
};
const getStripeProducts = createSlice({
    name: 'stripeProducts',
    initialState:getStripeProductsInitialState,
    reducers: {
        getStripeProductsStart: state => {
            state.isLoading = true;
        },
        getStripeProductsSuccess: (state, action) => {
            console.log(state,action)
            state.isLoading = false;
            state.data = action.payload;
        },
        getStripeProductsError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
})
export const {getStripeProductsStart,getStripeProductsSuccess,getStripeProductsError}=getStripeProducts.actions
export default {getStripeProducts:getStripeProducts.reducer}