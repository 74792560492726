import { domains } from "./domainlist";


const domain_prefix = domains.node;
export const userApis = {
  initial_signup: domain_prefix + "/v1/auth/initial-signup",
  user_details: domain_prefix + "/v1/auth/register",
  sign_in: domain_prefix + "/v1/auth/login",
  forgetPassword: domain_prefix + "/v1/auth/forgot-password",
  resetPassword: domain_prefix + "/v1/auth/reset-password",
  getUser: domain_prefix + "/v1/auth/get-user-data",
  updateLocation:domain_prefix + "/v1/auth/update-location-info",
  updateUser:domain_prefix + "/v1/auth/update-user-info",
  updatePassword:domain_prefix + "/v1/auth/change-password",
  createEmployee: domain_prefix + "/v1/auth/user-signup"
}

export const stripeApis = {
    getStripeProducts : domain_prefix + "/v1/stripe/get-stripe-products"
}
export const organization = {
    create_organization : domain_prefix + "/v1/orgnaization/create-orgnaization",
    update_organization : domain_prefix + "/v1/orgnaization/update-orgnaization",
    getOrganizationType : domain_prefix + "/v1/orgnaization/get-organization_type",
    addOrganizationLocation : domain_prefix + "/v1/orgnaization/add-organization",
    getOrganizationDetail: domain_prefix + "/v1/orgnaization/get-organization"
}
export const locationApi = {
    create_location : domain_prefix + "/v1/orgnaization/create-organization_location",
    fetch_all_locations : domain_prefix + "/v1/orgnaization/getAll-organization_location",
    update_location : domain_prefix + "/v1/orgnaization/update-organization_location",
    delete_location : domain_prefix + "/v1/orgnaization/delete-organization_location",
    // fetch_location : domain_prefix + "/v1/orgnaization/get-organization_type"
}


export const designationApi = {
    fetch_designations : domain_prefix + "/v1/orgnaization/get-designantion",
    create_designation : domain_prefix + "/v1/orgnaization/create-designantion",
    delete_designation : domain_prefix + "/v1/orgnaization/delete-designantion",
    update_designation : domain_prefix + "/v1/orgnaization/update-designantion",
}


export const departmentApi = {
    fetch_departments : domain_prefix + "/v1/orgnaization/getAll-organization-department",
    create_department : domain_prefix + "/v1/orgnaization/create-department",
    delete_department : domain_prefix + "/v1/orgnaization/delete-department",
    update_department : domain_prefix + "/v1/orgnaization/update-department",
}

export const workflowApi = {
    create_workflow : domain_prefix + "/v1/orgnaization/create-workflow",
    get_browsedata : domain_prefix + "/v1/orgnaization/list-workflow",
    publish_workflows : domain_prefix + "/v1/orgnaization/list-publish-workflow",
    
    delete_browsedata : domain_prefix + "/v1/orgnaization/delete-workflow",
    update_browsedata : domain_prefix + "/v1/orgnaization/update-workflow",
    update_browsedata2 : domain_prefix + "/v1/orgnaization/update-workflow-second",
    update_browsedata3 : domain_prefix + "/v1/orgnaization/update-workflow-third",
    publish_workflow : domain_prefix + "/v1/orgnaization/publish-workflow",
    get_single_browsedata : domain_prefix + "/v1/orgnaization/get-workflow",
    create_workflowPermission:domain_prefix + "/v1/orgnaization/create-permision",
}
export const workflowTicketApi = {
    create_wf_ticket : domain_prefix + "/v1/orgnaization/create-wf-ticket",
    update_wf_ticket : domain_prefix + "/v1/orgnaization/update-wf-ticket",
    get_single_ticket : domain_prefix + "/v1/orgnaization/get-wf-ticket",
    list_wf_ticket : domain_prefix + "/v1/orgnaization/list-wf-ticket",
}

export const employeeApi = {
    fetch_employees : domain_prefix + "/v1/orgnaization/get-employees",
    department_employees : domain_prefix + "/v1/orgnaization/get-employees",
    create_employee : domain_prefix + "/v1/orgnaization/create-employee",
    delete_employee : domain_prefix + "/v1/orgnaization/delete-employee",
    update_employee : domain_prefix + "/v1/orgnaization/update-employee",
    inviteEmployee: domain_prefix + "/v1/orgnaization/invitation-employee-token/token="

}