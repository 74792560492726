import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducer";
import { configureStore } from "@reduxjs/toolkit";
// const store = createStore(rootReducer, applyMiddleware(thunk));
import createuser from "./reducer/createuser";
import stripe from "./reducer/stripe";
import organization from "./reducer/organization";

export default configureStore({
  reducer: {
    createuser: createuser.createuser,
    user_details: createuser.user_details,
    stripe_products: stripe.getStripeProducts,
    forgetPassword: createuser.forgetPassword,
    resetPassword: createuser.resetPassword,
    login: createuser.login,
    getUser: createuser.getUser,
    updateUser: createuser.updateUser,
    updateLocation: createuser.updateLocation,
    updatePassword: createuser.updatePassword,
    organization: organization.createOrganization,
    getOrganizationType: organization.getOrganizationType,
    getOrganizationDetail: organization.getOrganizationDetail,
    createLocation: organization.createLocationDetails,
    fetchLocations:organization.fetchLocationsDetails,
    updateLocations:organization.updateLocationsDetails,
    deleteLocation:organization.deleteLocationsDetails,
    fetchDesignations: organization.fetchDesignations,
    deleteDesignation: organization.deleteDesignation,
    createDesignation: organization.createDesgination,
    updateDesignation: organization.updateDesignation,
    fetchDepartments: organization.fetchDepartments,
    deleteDepartment: organization.deleteDepartment,
    createDepartment: organization.createDepartment,
    updateDepartment: organization.updateDepartment,
    createEmployee: createuser.createEmployee,
    fetchEmployees: organization.fetchEmployees,
    deleteEmployee: organization.deleteEmployee,
    updateEmployee: organization.updateEmployee
  },
});
