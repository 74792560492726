import axios from "axios";
// import router from "@/router";
// import { useHistory } from "react-router-dom";
// const history= useHistory()

axios.interceptors.request.use(
  (req) => {
    let headers = {
      ...req.headers,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    console.log(headers);
    if (headers) {
      req.headers = headers;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    console.log(res);
    if (res.status === 200) {
    }
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      localStorage.removeItem('token')
      if(window.location.pathname != "/login"){
        window.location.href = "/login";
      }
      
      console.log("token not");
    }
    return Promise.reject(err);
  }
);

export default axios;
