import React, { lazy,useEffect } from 'react'

import { Redirect, Route } from "react-router-dom";
import { getUsers } from '../store/action/createuser'
import { useDispatch } from 'react-redux'
const Login = lazy(() => import('../pages/Login'))
const CreateAccount = lazy(() => import('../pages/CreateAccount'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const UserDetails = lazy(() => import('../pages/UserDetails'))
const ResetPassword = lazy(() => import('../pages/ResetPassword'))

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("token");
  console.log("this", isAuthenticated);

  const dispatch = useDispatch()

  useEffect(() => {
    getUsers(dispatch);
  }, []);

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : (
          <>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />

        <Redirect to="/login" />
        </>
        )
      }
    />
  );
}

export default ProtectedRoute;