import {
  createUserStart,
  createUserSuccess,
  createUserError,
  userDetailsStart,
  userDetailsError,
  userDetailsSuccess,
  forgetPasswordStart,
  forgetPasswordSuccess,
  forgetPasswordError,
  loginStart,
  loginError,
  loginSuccess,
  ResetPasswordStart,
  ResetPasswordError,
  ResetPasswordSuccess,
  getUserStart,
  getUserSuccess,
  getUserError,
  updateUserStart,
  updateUserError,
  updateLocationStart,
  updateLocationError,
  createEmployeeStart,
  createEmployeeSuccess,
  createEmployeeError,
  updatePasswordStart,updatePasswordError,updatePasswordSuccess
} from "../reducer/createuser";
import user from "../../services/user";


export const createEmployee = async (payload, dispatch) => {
  dispatch(createEmployeeStart());
  try {
    let res = await user.createEmployee(payload);
    if (res.data.success) {
      dispatch(createEmployeeSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(createEmployeeError());
    return e;
  }
};

export const createUser = async (payload, dispatch) => {
  dispatch(createUserStart());
  try {
    let res = await user.userSignUp(payload);
    if (res.data.success) {
      dispatch(createUserSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(createUserError());
    return e;
  }
};
export const forgetPassword = async (payload, dispatch) => {
  dispatch(forgetPasswordStart());
  try {
    let res = await user.forgetPassword(payload);
    if (res.data.success) {
      dispatch(forgetPasswordSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(forgetPasswordError());
    return e;
  }
};
export const userDetails = async (payload, dispatch) => {
  dispatch(userDetailsStart());
  try {
    let res = await user.userDetails(payload);
    if (res.data.success) {
      dispatch(userDetailsSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(userDetailsError());
    return e;
  }
};
export const login = async (payload, dispatch) => {
  dispatch(loginStart());
  try {
    let res = await user.userSignIn(payload);

    if (res.data.success) {
      dispatch(loginSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(loginError());
    return e;
  }
};

export const reset_password = async (payload, dispatch) => {
  dispatch(userDetailsStart());
  try {
    let res = await user.userDetails(payload);
    if (res.data.success) {
      dispatch(userDetailsSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(userDetailsError());
    return e;
  }
};
export const getUsers = async (dispatch) => {
  dispatch(getUserStart());
  try {
    let res = await user.getUser();
    console.log(res);
    if (res.data.success) {
      dispatch(getUserSuccess(res.data.payload));
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(getUserError());
    return e;
  }
};
export const updateUser = async (payload, dispatch) => {
  dispatch(updateUserStart());
  console.log(payload);
  try {
    let res = await user.updateUser(payload);
    console.log(res);
    if (res.data.success) {
      dispatch(updateUserStart());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(updateUserError());
    return e;
  }
};
export const updatePassword = async (payload, dispatch) => {
  dispatch(updatePasswordStart());
 
  try {
    let res = await user.updatePassword(payload);
    console.log(res);
    if (res.data.success) {
      dispatch(updatePasswordSuccess());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(updatePasswordError());
    return e;
  }
};
export const updateLocation = async (payload, dispatch) => {
  console.log("payload from action", payload);
  dispatch(updateLocationStart());
  try {
    let res = await user.updateLocation(payload);
    console.log(res);
    if (res.data.success) {
      dispatch(updateLocationError());
      return res;
    } else {
      return res;
    }
  } catch (e) {
    console.log(e);
    dispatch(updateLocationError());
    return e;
  }
};
