import axios from '../axios/axios'
import { userApis } from '../Api/apiroutes';

export default {
    userSignIn: async (payload) => {
        return await axios.post(userApis.sign_in, payload);
      },
      userSignUp: async (payload) => {
        console.log("payload",payload)
        return await axios.post(userApis.initial_signup, payload);
      },
      userDetails: async (payload) => {
        console.log("payload",payload)
        return await axios.post(userApis.user_details, payload);
      },
      forgetPassword: async (payload) => {
        console.log("payload",payload)
        return await axios.post(userApis.forgetPassword, payload);
      },
      resetPassword: async (payload) => {
        console.log("payload",payload)
        return await axios.post(userApis.resetPassword, payload);
      },
      getUser: async (payload)=>{
        return await axios.get(userApis.getUser)
        
      },
      updateUser: async (payload)=>{
        return await axios.post(userApis.updateUser,payload)
        
      },
      updateLocation: async (payload)=>{
        return await axios.post(userApis.updateLocation,payload)
        
      },
      updatePassword: async (payload)=>{
        return await axios.post(userApis.updatePassword,payload)
        
      },
      
      createEmployee: async (payload)=>{
        return await axios.post(userApis.createEmployee,payload)
        
      },
      
      
}